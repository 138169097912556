import React from 'react'
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { CardActions } from '@mui/material';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Timer from './Timer';
const EarningAnalysis = () => {
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const analysis = useSelector((state) => state.earning_rates.earning_rates);
  return (
    <div>
       {analysis.length > 0 &&
        <Grid container px={2} mt={0}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {analysis.map(investment =>(
        investment.status === 'approved' &&
        <Grid key={investment.id} item xs={12} md={4}>
            <Card  sx={{marginTop: '0px', paddingX: '2px', marginBottom: '0px'}} elevation={2}>
           
            <CardContent sx={{marginBottom: '-30px'}}>
                <Typography gutterBottom variant="h5" component="div">
                AMOUNT: {formatPrice(investment.amount)}
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <ReactApexChart options={{
              chart: {
                type: 'pie',
              },
              colors: ['#3758b7', '#ff0707'],
              legend: {
                show: false,
                showForSingleSeries: false,
                showForNullSeries: false,
                showForZeroSeries: false,
                position: 'middle'
              },
              
              labels: ['EARNING', 'PENDING'],
              dataLabels: {
                offsetX: 0,
                offsetY: 0,
                textAnchor: 'start',
                formatter(val, opts) {
                    
                  const name = opts.w.globals.labels[opts.seriesIndex]
                  return [name, val.toFixed(1) + '%']
                  
                },
                
              },
              plotOptions: {
                pie: {
                  dataLabels: {
                    offset: -20
                  }
                }
              }
              ,
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  
                }
              }]
            }}
            series={[parseFloat(investment.current_earning), parseFloat(investment.total - investment.current_earning)]}
            type="pie" height={180}
            />
            </Box>
                        
            </CardContent>

            <CardActions sx={{display: 'flex', justifyContent: 'center'}}>
               <Timer earning={parseFloat(investment.current_earning)} rate={parseFloat(investment.rate)}/>
            </CardActions>

        </Card>
        </Grid> 
      ))
      
    }
    </Grid>
      
      }


    </div>
  )
}

export default EarningAnalysis
